/** @jsx jsx */
import { jsx } from "theme-ui"
import "../assets/scss/style.scss"
import Seo from "../components/seo"
import Header from "./header"
import Logo from "./logo"
import Navigation from "./navigation"
import { IconContext } from "react-icons"
import theme from "../gatsby-plugin-theme-ui"
import Footer from "./footer"

const Layout = ({ children, className }) => {
  // const { site } = useStaticQuery(query)

  return (
    <div className="primary-container">
      <Header>
        <Logo />
        <Navigation />
        {/* <div sx={layoutStyle.theme}>
          <Theme />
        </div> */}
      </Header>
      <Seo />
      <main className={"container " + className}>
        <IconContext.Provider value={{ color: theme.colors.muted }}>
          {children}
        </IconContext.Provider>
      </main>
      <Footer />
    </div>
  )
}

export default Layout

// const layoutStyle = {
//   theme: {
//     display: ["none", "none", "none", "block"],
//   },
// }
