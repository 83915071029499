/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import { Link } from "gatsby"
// import { RiMenu3Line, RiCloseLine } from "react-icons/ri"
import { GoLocation as IconLocation } from "react-icons/go"
import { FaWhatsapp as IconMobile, FaPhone as IconPhone } from "react-icons/fa"
// import Theme from "../components/theme"
import site from "../util/site.json"
const { address, addressUrl, mobile, wpUrl, phone, phoneUrl } = site.meta

const ListLink = props => (
  <li>
    <Link to={props.to}>{props.children}</Link>
  </li>
)

class Navigation extends React.Component {
  constructor(props) {
    super(props)
    this.state = { showMenu: false }
    // this.handleToggleClick = this.handleToggleClick.bind(this)

    this.menuItems = [
      {
        externalUrl: addressUrl,
        title: address,
        icon: () => <IconLocation color="#4a89f3" size="1em" />,
      },
      {
        externalUrl: wpUrl,
        title: mobile,
        icon: () => <IconMobile color="#25D366" size="1em" />,
      },
      {
        externalUrl: phoneUrl,
        title: phone,
        icon: () => <IconPhone color="white" size="1em" />,
      },
    ]
  }

  // handleToggleClick() {
  //   this.setState(state => ({
  //     showMenu: !state.showMenu,
  //   }))
  // }

  render() {
    const listMenuItems = this.menuItems.map((menuItem, index) =>
      menuItem.path ? (
        <ListLink key={index} to={menuItem.path}>
          {menuItem.icon ? menuItem.icon() : null} {menuItem.title}
        </ListLink>
      ) : (
        <li key={index}>
          <a href={menuItem.externalUrl}>
            {menuItem.icon ? menuItem.icon() : null} {menuItem.title}
          </a>
        </li>
      )
    )
    return (
      <nav className="site-navigation" sx={navStyle.menu}>
        {/* <button
          onClick={this.handleToggleClick}
          className={"menu-trigger" + (this.state.showMenu ? " is-active" : "")}
        >
          <div className="icon-menu-line">
            <RiMenu3Line />
          </div>
          <div className="icon-menu-close">
            <RiCloseLine />
          </div>
        </button> */}
        <ul>
          {listMenuItems}
          <div sx={navStyle.border}></div>
          {/* <div sx={navStyle.theme}><Theme/></div> */}
        </ul>
      </nav>
    )
  }
}

export default Navigation

const navStyle = {
  menu: {
    ul: {
      bg: "primary",
    },
  },
  theme: {
    display: ["block", "block", "block", "none"],
    p: " 25px 20px 20px",
  },
  border: {
    bg: "borderColor",
    borderTop: "1px solid transparent",
    display: ["block", "block", "block", "none"],
  },
}
