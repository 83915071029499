/** @jsx jsx */
import { jsx } from "theme-ui"
import { Link } from "gatsby"
import { GrShieldSecurity } from "react-icons/gr"

const Footer = () => (
  <footer
    className="site-footer"
    sx={{
      bg: "primary",
    }}
  >
    <div className="container">
      <p style={{ fontSize: "large" }}>
        Kale Kilit Bayii{" "}
        <span>
          <GrShieldSecurity size="1rem" />
        </span>{" "}
        <Link to="/">Ev ve İşyeri Güvenliği</Link>
      </p>
    </div>
  </footer>
)

export default Footer
