import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const Logo = () => {
  return (
    <div className="site-logo-container">
      <Link to="/">
        <StaticImage
          alt="logo - kale kilit"
          className="site-logo-image"
          src="../images/logo_new.png"
        />
      </Link>
    </div>
  )
}

export default Logo
